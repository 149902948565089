var site = site || {};
site.Carousel = (function () {
    // Functions

    var copyWithCarousel = function(){
        if(document.getElementsByClassName('js-copy-with-carousel').length){
            const copyWithCarousels = document.querySelectorAll('.js-copy-with-carousel');
            copyWithCarousels.forEach(function(copyWithCarousel, i){
                const prevBtn = copyWithCarousel.previousElementSibling;
                const nextBtn = copyWithCarousel.nextElementSibling;

                let carousel = new Swiper(copyWithCarousel, {
                    slidesPerView: 1.25,
                    slideOffsetAfter: "15px",
                    slideOffsetAfter: "15px",
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    },
                    breakpoints: {
                        576: {
                            slidesPerView: 2,
                            slidesPerGroup: 1,
                        },
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 1,
                        },
                    },
                })
            })
        }
    }

    var atlasDetailCarousel = function(){
        if(document.getElementsByClassName('js-atlas-detail-carousel').length){
            const atlasDetailCarousels = document.querySelectorAll('.js-atlas-detail-carousel');
            atlasDetailCarousels.forEach(function(atlasDetailCarousel, i){
                const startIndex = atlasDetailCarousel.dataset.startIndex;
                const buttons = atlasDetailCarousel.nextElementSibling;

                const previousButton = buttons.querySelector('.js-previous-btn');
                const nextButton = buttons.querySelector('.js-next-btn');

                const carousel = new Swiper(atlasDetailCarousel, {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: true,
                    initialSlide: Number(startIndex - 1),
                    navigation: {
                        nextEl: nextButton,
                        prevEl: previousButton,
                    },
                    breakpoints: {
                        576: {
                            slidesPerView: 2
                        }
                    }
                })
            })
        }
    }


    // Return
    return {
        copyWithCarousel : copyWithCarousel,
        atlasDetailCarousel : atlasDetailCarousel
    };
}());