var site = site || {};
site.VideoMonger = (function() {
    var toggleVideos = function(){
        const videoTriggers = document.querySelectorAll(".js-video-trigger");

        videoTriggers.forEach(function(videoTrigger) {
            const videoName = videoTrigger.querySelector("h3").innerHTML;
            const btnTrigger = videoTrigger.querySelector("button");
            const videoContainer = videoTrigger.nextElementSibling;
            const video = videoContainer.querySelector(".js-video")
            const closeVideo = videoContainer.querySelector(".js-close-video");
            
            btnTrigger.addEventListener('click', function(){
                playerReady();
            })
    
            closeVideo.addEventListener('click', function(){
                handleClose();
            })  
    
            video.onpause = (event) => {
                handleClose();
            };
    
            const playerReady = function() {
                closeVideo.classList.remove('d-none');
                closeVideo.classList.add('d-flex');
                videoContainer.classList.add('z-index-4');
                video.play();
                dataLayer.push({'event': 'Video Played', 'videoFileName': videoName})
            }    
    
            const handleClose = function() {
                closeVideo.classList.add('d-none');
                closeVideo.classList.remove('d-flex');
                videoContainer.classList.remove('z-index-4');
                video.pause();
                
                let minutes = parseInt(video.currentTime / 60, 10);
                let seconds = video.currentTime % 60;
                let time = minutes+"m "+seconds.toFixed(0) + "s"
                dataLayer.push({'event': 'Video Paused', 'videoFileName': videoName, 'currentTime' : time});
            }
        })
    }

    // Return
    return {
        toggleVideos : toggleVideos
    };
})();