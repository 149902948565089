var site = site || {};
site.Modals = (function () {

    var openModalId = null;

    //console.log('Modals.bootstrap modals without jquery');
    //https://blog.carsonevans.ca/2020/08/08/bootstrap-modals-without-jquery/

    /**
     * Open bootstrap modal, load video source tag
     * @param {*} modalId 
     * @param {*} src 
     */
    var openVideoModal = function (modalId, src) {
        //console.log('Modals.openVideoModal:', modalId, src);
        var modal = document.getElementById(modalId),
            video = modal.getElementsByTagName('video'),
            source = document.createElement("source");
        // console.log('video:',video);
        // console.log('source:',source);
        source.src = src;
        video[0].appendChild(source);


        const backdrop = document.createElement('div');
        // Remove the show from the initial classList, we will add it in the timeout  backdrop.classList.add('modal-backdrop', 'fade', 'show');
        backdrop.classList.add('modal-backdrop', 'fade');
        document.body.classList.add('modal-open');
        document.body.appendChild(backdrop);
        modal.style.display = 'block';
        modal.setAttribute('aria-hidden', 'false', 'show');
        
        // We don't need to specify the milliseconds in this timeout, since we don't want a delay, we just want the changes to be done outside of the normal DOM thread.
        setTimeout(() => {
            // Move adding the show class to inside this setTimeout
            modal.classList.add('show');
            // Add the show class to the backdrop in this setTimeout
            backdrop.classList.add('show');
        });

        openModalId = modalId;
    }
    
    
    /**
     * Close bootstrap modal, remove video source tag to fully unload video
     * @param {*} modalId 
     */
    var closeVideoModal = function(modalId) {
        //console.log('Modals.closeVideoModal:', modalId);
        var modal = document.getElementById(modalId),
            video = modal.getElementsByTagName('video'),
            source = video[0].getElementsByTagName('source');
        // console.log('video:',video);
        // console.log('source:',source);
        source[0].remove();
        video[0].load();//need the load to fully remove the source

        const backdrop = document.querySelector('.modal-backdrop.fade.show');
        modal.setAttribute('aria-hidden', 'true');
        backdrop.classList.remove('show');
        // We want to remove the show class from the modal outside of the regular DOM thread so that transitions can take effect
        setTimeout(() => {
            modal.classList.remove('show');
            document.body.classList.remove('modal-open');
        });
        
        // We want to set the display style back to none and remove the backdrop div from the body with a delay of 500ms in order to give their transition/animations time to complete before totally hiding and removing them.
        setTimeout(() => {
            modal.style.display = 'none';
            backdrop.remove();
        }, 500); // this time we specified a delay

        openModalId = null;
    }



    if(document.getElementsByClassName('js-modal-close').length){
        //console.log('found js-modal-close');
        document.addEventListener('click', function(e){
            //console.log('document.click', openModalId, e.target);
            if (!e.target.matches('.js-modal-open, .js-modal-close, .js-modal-open *, .js-modal-close *')) {
                //console.log('no matches. openModalId:', openModalId);
                if(openModalId) {
                    closeVideoModal(openModalId);
                }
            }
        });
    }


    


    // Return
    return {
        openVideoModal : openVideoModal,
        closeVideoModal : closeVideoModal
    };
}());