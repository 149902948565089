var site = site || {};
site.CampusMap = (function () {
    // Functions
    //console.log("campus-map.js loaded");
    //https://codepen.io/websitebeaver/pen/oLGGNz

    var mto;//menu timeout

    //initialize
    var initMap = function(){
        //console.log("CampusMap.initMap");

        if(document.getElementsByClassName('js-campus-map').length){
            //console.log("found: js-campus-map");
            const campusMap = document.querySelector('.js-campus-map');

            const mapBtns = document.querySelectorAll('.js-map-btn');
            mapBtns.forEach(function(btn, i){
                //console.log('btn:', i, btn);

                const showCard = function(e) {
                    const thisId = this.parentElement.getAttribute('id'),
                    thisMenu = document.getElementById('menu-' + thisId);

                    thisMenu.classList.remove('d-none');//cant get width otherwise

                    const thisMenuWid = thisMenu.offsetWidth,
                        parentOffset = offset(this.parentElement.parentElement),
                        campusMapWidth = campusMap.offsetWidth,//cant get svg offsetWidth, gotta go up another level
                        thisOffset = offset(this),
                        menuOffsetTop = thisOffset.top - parentOffset.top;

                    let menuOffsetLeft = thisOffset.left - parentOffset.left + (thisMenuWid * .5);

                    if((menuOffsetLeft + thisMenuWid) > campusMapWidth) {
                        menuOffsetLeft = thisOffset.left - parentOffset.left - (thisMenuWid * .5);
                    }
                    
                    clearInterval(mto);
                    closeOtherMenus('menu-' + thisId);

                    thisMenu.style.top = menuOffsetTop + 'px';
                    thisMenu.style.left = menuOffsetLeft + 'px';
                }

                btn.addEventListener('mouseenter', showCard, false);
                btn.addEventListener('focus', showCard, false);

                const hideCard = function(){
                    var thisId = this.parentElement.getAttribute('id');
                    //console.log('mouseleave<<', thisId);
                    closeMenu('menu-' + thisId);
                }

                btn.addEventListener('mouseleave', hideCard);
                btn.addEventListener('blur', hideCard);
            });
            

            const menus = document.querySelectorAll('.js-map-menu');
            menus.forEach(function(menu, i){
                menu.classList.add('d-none');
                menu.addEventListener('mouseenter', function () {
                    clearInterval(mto);
                });
                menu.addEventListener('mouseleave', function () {
                    var thisId = this.getAttribute('id');
                    //console.log('menu.leave:', thisId);
                    closeMenu(thisId);
                });
            });
        }
    }

    //add mobile menu swiper
    var mobileCarousel = function(){
        if(document.getElementsByClassName('js-campus-map').length){
            var swiper = new Swiper(".js-mobile-campus-carousel", {
                slidesPerView: 1.5,
                spaceBetween: 10,
                centeredSlides: false,
                mousewheel: false,
                breakpoints: {
                    576: {
                        slidesPerView: 2.5
                    }
                },
            });

            swiper.on('click', function () {
                const swiperClickedIndex = swiper.clickedIndex;
                swiper.slideTo(swiperClickedIndex, 500);
            });

            swiper.on('slideChange', function () {
                const swiperIndex = swiper.activeIndex;
                swiper.slideTo(swiperIndex, 500);
            });

        }
    }




    //close menu after timeout - user might mouse into another menu/button
    function closeMenu(mid) {
        var thisMenu = document.getElementById(mid);
        clearInterval(mto);
        mto = setTimeout(() => {
            //console.log("Delayed for 100.", mid);
            thisMenu.classList.add('d-none');
        }, 100)
    }

    //close other menus aside from current
    function closeOtherMenus(mid){
        const menus = document.querySelectorAll('.js-map-menu');
        menus.forEach(function(menu, i){
            if(menu.getAttribute('id') != mid) {
                menu.classList.add('d-none');
            }
        });
    }


    //not sure this is needed, i can't mouse  out to resize quick enough to need this yet
    //resizeMap - clear out menus
    function resizeMap() {
        //console.log("CampusMap.resizeMap");
        const menus = document.querySelectorAll('.js-map-menu');
        menus.forEach(function(menu, i){
            menu.classList.add('hidden');
        });
    }

    //get element offset
    function offset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }


    // Return
    return {
        initMap : initMap,
        mobileCarousel : mobileCarousel,
        resizeMap : resizeMap
    };
}());