var site = site || {};
site.Utilities = (function () {
    // Functions

    // var functionName = function() {
    //
    // };

    var toggleOffCanvasNav = function () {
        const toggleNav = document.getElementById('js-toggle-nav');
        const offcanvasNav = document.getElementById('js-offcanvas-nav');

        if(offcanvasNav != null) {
            toggleNav.addEventListener('click', function(e) {
                toggleNav.classList.toggle('animate');
                offcanvasNav.classList.toggle('active');
    
                window.onscroll = function(){
                    if(window.scrollTop >= 300){
                        toggleNav.classList.remove('animate');
                        offcanvasNav.classList.remove('active');
                    }
                }
            });
    
            document.addEventListener('click', function(e){
                if (!e.target.matches('#js-toggle-nav, #js-toggle-nav *, #js-offcanvas-nav, #js-offcanvas-nav *')) {
                    toggleNav.classList.remove('animate');
                    offcanvasNav.classList.remove('active');
                }
            })
        }
    }

    var alertCookie = function () {
        const alertBox = document.getElementById('js-alert-box');

        if (alertBox != null) {
            const firstVisitContainer = alertBox.querySelector('.js-fv-container');
            const secondVisitContainer = alertBox.querySelector('.js-sv-container');
            
            const pageURL = window.location.pathname;
            const alertCookieName = pageURL + "-alert";

            if(getCookie(alertCookieName) == null){
                firstVisitContainer.style.display = "block";

                const d = new Date();
                const expDays = 365;
                d.setTime(d.getTime() + (expDays*24*60*60*1000));
                let expires = "expires="+ d.toUTCString();
                document.cookie = alertCookieName + "=Seen;" + expires + ";"
                document.cookie = "Welcome=true;"
            } else {
                if(getCookie("Welcome") == null){
                    secondVisitContainer.style.display = "block";
                } else {
                    firstVisitContainer.style.display = "block";
                }
            }
        }
    }

    var getCookie = function(cookieName) {
        var name = cookieName + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if ((c.indexOf(name)) == 0) {
                return c.slice(name.length);
            }
        }
        return null;
    }


    /**
     * Check Footer - if window height is taller than body, move footer to absolute bottom
    */
    var checkFooter = function () {
        //console.group('checkFooter');
        var winHeight = window.innerHeight,
            header = document.getElementsByTagName('header'),
            main = document.getElementById('main'),
            footer = document.getElementById('footer'),
            headerHeight = header ? header[0].offsetHeight : 0,
            mainHeight = main ? main.offsetHeight : 0,
            footerHeight = footer ? footer.offsetHeight : 0,
            bodyHeight = headerHeight + mainHeight + footerHeight;
            //bodyHeight = document.body.offsetHeight;//footer doesn't get added with body height when it's position:absolute

        // console.log('headerHeight:', headerHeight);
        // console.log('mainHeight:', mainHeight);
        // console.log('footerHeight:', footerHeight);
        // console.log('bodyHeight:', bodyHeight);
        // console.log('winHeight:', winHeight);
        // console.groupEnd();

        if(header && main && footer && winHeight > bodyHeight) {
            footer.classList.add('absolute-bottom');
        } else {
            footer.classList.remove('absolute-bottom');
        }

    }

    // Return
    return {
        toggleOffCanvasNav : toggleOffCanvasNav,
        alertCookie : alertCookie,
        checkFooter: checkFooter
    };
}());