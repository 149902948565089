var site = site || {};
site.Atlas = (function() {
    // Functions

    var SortGrid = function(){
        if(document.getElementById('js-atlas')){
            console.log("It Does Exist");

            document.querySelector('.js-sort-grid').addEventListener('click', function() {

                const gridContainer = document.querySelector('.js-atlas-grid');
                const cheeses = (a) => a.getAttribute('data-cheese-name');

                this.classList.toggle('active');
                
                this.querySelector('[data-fa-i2svg]').classList.toggle('fa-sort-alpha-down');
                this.querySelector('[data-fa-i2svg]').classList.toggle('fa-sort-alpha-up-alt');

                if(this.classList.contains('active')){
                    Array.from(gridContainer.children)
                        .sort((a, b) => cheeses(b).localeCompare(cheeses(a)))
                        .forEach(child => gridContainer.appendChild(child));
                }else {
                    Array.from(gridContainer.children)
                        .sort((a, b) => cheeses(a).localeCompare(cheeses(b)))
                        .forEach(child => gridContainer.appendChild(child));
                }
                
            })
        }
    }

    var filterByCheese = function() {
        if(document.getElementById('js-atlas')){
            const filterCheeseSelect = document.querySelector('.js-filter-cheese');
            const filterCompanySelect = document.querySelector('.js-filter-company');
            
            filterCheeseSelect.addEventListener('change', function() {
                const thisCheese = this.value;

                const cheeseCards = document.querySelectorAll('.js-cheese');

                let cheeseCardsLength = cheeseCards.length;

                cheeseCards.forEach(function(cheeseCard) {
                    const cheeseCardName = cheeseCard.getAttribute('data-cheese-name');

                    const cheeseCardCompany = cheeseCard.getAttribute('data-cheese-company');

                    if(cheeseCardName != thisCheese) {
                        cheeseCard.style.display = 'none';

                        cheeseCardsLength--;
                    } else {
                        cheeseCard.style.display = 'flex';

                        for(var i=0; i < filterCompanySelect.options.length; i++){
                            if(filterCompanySelect.options[i].value !== cheeseCardCompany && filterCompanySelect.options[i].value != ""){
                                filterCompanySelect.options[i].style.display = 'none';
                            } else {
                                filterCompanySelect.options[i].style.display = 'block';
                            } 
                        }
                    }
                })

                document.querySelector('.js-cheese-count').innerHTML = cheeseCardsLength;
            })
        }
    }

    var filterByCompany = function() {
        if(document.getElementById('js-atlas')){
            const filterCompanySelect = document.querySelector('.js-filter-company');
            const filterCheeseSelect = document.querySelector('.js-filter-cheese');
            
            filterCompanySelect.addEventListener('change', function() {
                const thisCompany = this.value;
                filterCheeseSelect.value = "";

                const cheeseCards = document.querySelectorAll('.js-cheese');

                let cheeseCardsLength = cheeseCards.length;
                let visibleCheeseCards;

                console.log(cheeseCards.length)

                cheeseCards.forEach(function(cheeseCard) {
                    const cheeseCardCompany = cheeseCard.getAttribute('data-cheese-company');

                    const cheeseCardName = cheeseCard.getAttribute('data-cheese-name');

                    if(cheeseCardCompany != thisCompany) {
                        cheeseCard.style.display = 'none';

                        cheeseCardsLength--;
                    } else {
                        cheeseCard.style.display = 'flex';

                        for(var i=0; i < filterCheeseSelect.options.length; i++){
                            const optionVal = filterCheeseSelect.options[i].value
                            if(optionVal !== cheeseCardName && optionVal != "" && !visibleCheeseCards.includes(optionVal)){
                                filterCheeseSelect.options[i].style.display = 'none';
                            } else {
                                filterCheeseSelect.options[i].style.display = 'block';
                                visibleCheeseCards += cheeseCardName
                            } 
                        }
                    }
                })

                document.querySelector('.js-cheese-count').innerHTML = cheeseCardsLength;
            })
        }
    }

    var resetFilters = function() {
        if(document.getElementById('js-atlas')){
            const resetFiltersBtn = document.querySelector('.js-reset-filters');
            const filterCheeseSelect = document.querySelector('.js-filter-cheese');
            const filterCompanySelect = document.querySelector('.js-filter-company');
            
            resetFiltersBtn.addEventListener('click', function() {
                filterCheeseSelect.value = "";
                filterCompanySelect.value = "";

                for(var i=0; i < filterCompanySelect.options.length; i++){
                    filterCompanySelect.options[i].style.display = 'block'; 
                }

                for(var i=0; i < filterCheeseSelect.options.length; i++){
                    filterCheeseSelect.options[i].style.display = 'block'; 
                }

                const cheeseCards = document.querySelectorAll('.js-cheese');

                cheeseCards.forEach((cheeseCard) => {            
                    cheeseCard.style.display = 'flex';
                })

                document.querySelector('.js-cheese-count').innerHTML = cheeseCards.length;
            })
        }
    }

    // Return
    return {
        SortGrid : SortGrid,
        filterByCheese : filterByCheese,
        filterByCompany : filterByCompany,
        resetFilters : resetFilters
    };
})();